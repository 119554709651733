import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import MainTemplate from 'components/templates/MainTemplate';
import representatives from 'components/pages/RepresentativesPage/representatives';
import NotFoundPage from 'components/pages/NotFoundPage';
import Images from './Images';

const Title = styled.h1`
  font-weight: normal;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: -0.8em;
  margin-bottom: 3em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    align-items: flex-start;
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 0.8em;
  margin-bottom: 0.8em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 60%;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;

const ImagesWrapper = styled.div`
  margin-top: 0.8em;
  margin-bottom: 0.8em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 40%;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;

const RepresentativePage = ({ match }) => {
  const representative = representatives.get(match.params.key);

  if (!representative) {
    return <NotFoundPage />;
  }

  return (
    <MainTemplate>
      <Helmet>
        <title>Представитель ООО &quot;Золотая Камея&quot; в г. {representative.city}</title>
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <script
          type="text/javascript"
          async=""
          src={`https://api-maps.yandex.ru/services/constructor/1.0/js/?sid=${representative.mapSid}&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;sourceType=constructor&amp;scroll=true&amp;id=representative-${match.params.key}-map`}
        />
      </Helmet>
      <Breadcrumbs
        links={[
          {
            link: '/contacts',
            text: 'Контакты',
          },
          {
            link: '/contacts/predstaviteli-v-regionah',
            text: 'Наши представители',
          },
        ]}
      />
      <Title>Представитель ООО &quot;Золотая Камея&quot; в г. {representative.city}</Title>

      <Wrapper>
        {representative.images && representative.images.length > 0 && (
          <ImagesWrapper>
            <Images images={representative.images} />
          </ImagesWrapper>
        )}
        <ContentWrapper>
          <p>
            <strong>Адрес:</strong> {representative.region ? `${representative.region}, ` : ''}
            г. {representative.city}, {representative.address}
          </p>
          <p>
            <strong>Телефон:</strong> 8 800-700-17-89, {representative.phone}
          </p>
          <p>
            <strong>E-mail:</strong> info@gold-kamea.ru
          </p>
          <p>
            <strong>Контактное лицо:</strong> {representative.person}
          </p>
          {representative.time && (
            <p>
              <strong>Режим работы:</strong> {representative.time}
            </p>
          )}
        </ContentWrapper>
      </Wrapper>

      <div id={`representative-${match.params.key}-map`} />
    </MainTemplate>
  );
};

export default RepresentativePage;
