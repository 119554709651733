import { Component } from 'react';
import styled, { css } from 'styled-components';
import Lightbox from 'react-images';

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 -0.5em;
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 0.5em;
    flex: 1 1 auto;
  }
`;

const Previews = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  height: 3.1em;
  margin-top: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
    margin: 0 0.5em;
    width: 3.1em;
    flex: none;
    height: auto;
  }
`;

const selectedPreviewCss = css`
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  opacity: 1;
`;

const Preview = styled.img`
  display: block;
  flex: none;
  opacity: 0.5;
  margin-right: 0.6em;
  height: 100%;

  ${({ isImageSelected }) => isImageSelected && selectedPreviewCss};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    height: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.6em;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

export default class Images extends Component {
  state = {
    selectedImageIndex: 0,
    lightboxIsOpen: false,
  };

  selectImage = (selectedImageIndex) => {
    this.setState({
      selectedImageIndex,
    });
  };

  openLightbox = () => {
    this.setState({
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  };

  gotoPrevLightboxImage = () => {
    this.setState((state) => {
      const prevIndex = state.selectedImageIndex - 1;

      return {
        selectedImageIndex: prevIndex < 0 ? this.props.images.length - 1 : prevIndex,
      };
    });
  };

  gotoNextLightboxImage = () => {
    this.setState((state) => {
      const nextIndex = state.selectedImageIndex + 1;

      return {
        selectedImageIndex: nextIndex > this.props.images.length - 1 ? 0 : nextIndex,
      };
    });
  };

  render() {
    const { images } = this.props;

    return (
      <>
        <Wrapper>
          <ImageWrapper onClick={images.length > 0 ? this.openLightbox : () => {}}>
            <Image
              src={images[this.state.selectedImageIndex]}
              alt={`Изображение ${this.state.selectedImageIndex + 1}`}
            />
          </ImageWrapper>
          {images.length > 1 && (
            <Previews>
              {images.map((image, i) => (
                <Preview
                  key={images[this.state.selectedImageIndex]}
                  src={images[this.state.selectedImageIndex]}
                  onClick={() => this.selectImage(i)}
                  isImageSelected={i === this.state.selectedImageIndex}
                  alt={`Изображение ${i + 1}`}
                />
              ))}
            </Previews>
          )}
        </Wrapper>
        <Lightbox
          images={images.map((image) => ({
            src: image,
            thumbnail: image,
          }))}
          currentImage={this.state.selectedImageIndex}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
          imageCountSeparator=" из "
          onClickPrev={this.gotoPrevLightboxImage}
          onClickNext={this.gotoNextLightboxImage}
          onClickThumbnail={this.selectImage}
          showThumbnails={images.length > 1}
          backdropClosesModal
        />
      </>
    );
  }
}
